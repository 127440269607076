import * as React from 'react';
import { RichText } from 'prismic-reactjs';
import { serializeHyperlink } from '../../utils/helpers';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const FooterText = (props) => {
  return (
    <div className={`h1_home ${props?.className}`}>
      <div className="row">
        <div className="col-12">
          <div className="logo">
            <Link to="/">
              <StaticImage
                width={200}
                src="../../images/activeskin-logo-white.png"
                alt="Logo Activeskin"
              />
            </Link>
          </div>

          <RichText render={props?.title?.richText} serializeHyperlink={serializeHyperlink} />
          <RichText render={props?.text?.richText} serializeHyperlink={serializeHyperlink} />

          <Link to="/" className="read-more">
            Read More
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FooterText;
